<template>
  <tp-modal
    max-width="960px"
    name="modal-customer"
    width="70%"
    @open="handleOpen"
  >
    <v-card slot-scope="props">
      <v-toolbar
        class="px-5 pb-0 align-start"
        extension-height="40px"
        height="56x"
      >
        <v-toolbar-title class="font-weight-bold">
          <span>{{ title }}</span>
          <span v-if="!customer.guest && customer.phone" class="ml-1"
            >({{ customer.phone }})</span
          >
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <!--<v-btn
          v-if="customer.id"
          icon
          color="red accent-1"
          @click="deleteCustomer()"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>-->

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs v-model="activeTab" height="40px">
            <template v-if="!customer.id">
              <v-tab
                v-for="item in tabItems.slice(0, 1)"
                :key="item.id"
                class="font-weight-bold"
              >
                {{ item.text }}
              </v-tab>
            </template>
            <template v-else>
              <v-tab
                v-for="item in tabItems"
                :key="item.id"
                class="font-weight-bold"
              >
                {{ item.text }}
              </v-tab>
            </template>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="activeTab" class="modal-body-scroll">
        <v-tab-item
          v-for="item in tabItems"
          :key="item.id"
          class="tab-item grey lighten-3 pa-5"
        >
          <component
            :is="
              (item.id === 'bank-account' && !customer.id) ||
              (item.id === 'transaction-history' && !customer.id)
                ? null
                : `tab-${item.id}`
            "
            :edit-item-index="editAddressItemIndex"
            :is-edit-mode="isEditAddressMode"
            @changeTabToAddAddress="activeTab = 1"
            @onSuccessfulCreate="
              onSuccessfulCreate(props.payload.onSuccessfulCreate || undefined)
            "
            @updateEditAddressItemIndex="editAddressItemIndex = $event"
            @updateIsEditAddressMode="isEditAddressMode = $event"
          ></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </tp-modal>
</template>

<script>
import TabAddress from "./components/TabAddress";
import TabBankAccount from "./components/TabBankAccount";
import TabInfo from "./components/TabInfo";
import TabTransactionHistory from "./components/TabTransactionHistory";
import TabDebtHistory from "./components/TabDebtHistory.vue";
import { BANK_ACCOUNT_OBJECT_TYPE } from "@/core/constant";

export default {
  components: {
    TabAddress,
    TabBankAccount,
    TabInfo,
    TabTransactionHistory,
    TabDebtHistory
  },
  data() {
    return {
      activeTab: null,
      editAddressItemIndex: null,
      isEditAddressMode: false
    };
  },
  computed: {
    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    },
    customerAddresses() {
      return this.$store.getters["CUSTOMER/customerAddresses"];
    },
    customerBankAccounts() {
      return this.$store.getters["BANK_ACCOUNT/objectBankAccounts"];
    },
    customerStatusRequest() {
      return this.$store.getters["CUSTOMER/statusRequest"];
    },
    customerTransactionHistory() {
      return this.$store.getters["CUSTOMER/customerTransactionHistory"];
    },
    tabItems() {
      return [
        { id: "info", text: "Thông tin" },
        { id: "address", text: "Địa chỉ" },
        { id: "bank-account", text: "Tài khoản ngân hàng" },
        { id: "debt-history", text: "Lịch sử công nợ" },
        { id: "transaction-history", text: "Lịch sử giao dịch" }
      ];
    },
    title() {
      if (!this.customer.id) return "Thêm mới khách hàng";

      if (this.customer.guest) return "Khách hàng không đăng nhập";

      return `Khách hàng ${this.customer.code} - ${this.customer.name}`;
    }
  },
  watch: {
    async activeTab(val) {
      if (val === 0) {
        await this.$store.dispatch(
          "CUSTOMER/getCustomerById",
          this.customer.id
        );
      }
      if (val === 1) {
        this.getCustomerAddresses();
      }
      if (val === 2) {
        this.$store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
          id: this.customer.id,
          type: BANK_ACCOUNT_OBJECT_TYPE.USER
        });
      }
      if (val === 3 && this.customerTransactionHistory.length === 0) {
        this.getCustomerTransactionHistory();
      }
    }
  },
  created() {
    this.activeTab = 0;
  },
  methods: {
    handleOpen() {
      this.activeTab = 0;
    },
    closeModal() {
      this.$store.dispatch("CUSTOMER/resetStatusRequest");
      this.$store.dispatch("CUSTOMER/resetCustomer");
      this.$store.dispatch("CUSTOMER/resetCustomerAddresses");
      this.$modal.hide({
        name: "modal-customer"
      });
      this.activeTab = null;
      this.editAddressItemIndex = null;
      this.isEditAddressMode = false;
    },

    deleteCustomer() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa khách hàng <strong>"${this.customer.code}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("CUSTOMER/deleteCustomer", {
                data: this.customer.id,
                routeQuery: {
                  filters: {
                    tradeStatus: routeQuery.trans_status
                      ? routeQuery.trans_status
                      : null,
                    fromBuy: routeQuery.buy_from
                      ? parseFloat(routeQuery.buy_from)
                      : null,
                    toBuy: routeQuery.buy_to
                      ? parseFloat(routeQuery.buy_to)
                      : null,
                    fromReturn: routeQuery.return_from
                      ? parseFloat(routeQuery.return_from)
                      : null,
                    toReturn: routeQuery.return_from
                      ? parseFloat(routeQuery.return_from)
                      : null,
                    fromBalance: routeQuery.debt_from
                      ? parseFloat(routeQuery.debt_from)
                      : null,
                    toBalance: routeQuery.debt_to
                      ? parseFloat(routeQuery.debt_to)
                      : null
                  },
                  search: routeQuery.search || null,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });
              if (
                this.customerStatusRequest.value === "success-deleteCustomer"
              ) {
                this.$modal.hide({ name: "modal-customer" });
                await this.$store.dispatch("CUSTOMER/resetCustomer");
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    },

    async getCustomerAddresses() {
      await this.$store.dispatch(
        "CUSTOMER/getCustomerAddresses",
        this.customer.id
      );
    },

    async getCustomerTransactionHistory() {
      await this.$store.dispatch("CUSTOMER/getCustomerTransactionHistory", {
        id: this.customer.id
      });
    },

    onSuccessfulCreate(fn) {
      if (typeof fn === "function") {
        fn(this.customer);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 500px;
}
</style>
