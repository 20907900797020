<template>
  <div>
    <card-address
      class="mb-5"
      v-for="(item, index) in customerAddresses"
      :key="index"
      :edit-item-index="editItemIndex"
      :is-edit-mode="isEditMode"
      :cities="cities"
      :districts="districts"
      :wards="wards"
      :item="item"
      :item-index="index"
      @updateEditItemIndex="updateEditItemIndex($event)"
      @updateIsEditMode="updateIsEditMode($event)"
    ></card-address>
    <v-btn
      class="rounded-lg mr-2"
      depressed
      color="primary"
      @click="addEmptyCustomerAddress()"
    >
      Thêm địa chỉ
    </v-btn>
  </div>
</template>

<script>
import CardAddress from "./CardAddress";
import LocalAdministrationService from "@/core/service/local-administration.service";

export default {
  components: {
    CardAddress
  },
  props: {
    editItemIndex: {
      type: Number
    },
    isEditMode: {
      type: Boolean
    }
  },
  data() {
    return {
      cities: [],
      districts: [],
      wards: []
    };
  },
  computed: {
    customerAddresses() {
      return this.$store.getters["CUSTOMER/customerAddresses"];
    },
    customerAddress() {
      return this.$store.getters["CUSTOMER/customerAddress"];
    },
    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    }
  },

  async created() {
    await this.getCities();

    if (this.customerAddress.tinh_thanhpho) {
      await this.getDistrictsOfCity(this.customerAddress.tinh_thanhpho);
    }

    if (this.customerAddress.quan_huyen) {
      await this.getWardsOfDistrict(this.customerAddress.quan_huyen);
    }
  },

  methods: {
    async addEmptyCustomerAddress() {
      console.log(this.customer.phone);
      await this.customerAddresses.push({
        of_user_id: null,
        name: this.customer.name,
        phone: this.customer.phone,
        tinh_thanhpho: null,
        quan_huyen: null,
        phuong_xa: null,
        so_nha: null
      });

      await this.$store.dispatch("CUSTOMER/setCustomerAddress", {
        of_user_id: null,
        name: this.customer.name,
        phone: this.customer.phone,
        tinh_thanhpho: null,
        quan_huyen: null,
        phuong_xa: null,
        so_nha: null
      });

      // Check empty item
      if (
        this.editItemIndex &&
        !this.customerAddresses[this.editItemIndex].id
      ) {
        this.customerAddresses.splice(this.editItemIndex, 1);
      }

      this.$emit("updateIsEditAddressMode", true);
      this.$emit(
        "updateEditAddressItemIndex",
        this.customerAddresses.length - 1
      );
    },

    updateEditItemIndex(index) {
      // Check empty item
      if (
        this.editItemIndex &&
        !this.customerAddresses[this.editItemIndex].id
      ) {
        this.customerAddresses.splice(this.editItemIndex, 1);
      }

      this.$emit("updateEditAddressItemIndex", index);
    },

    updateIsEditMode(val) {
      this.$emit("updateIsEditAddressMode", val);
    },

    async getCities() {
      const response = await LocalAdministrationService.getCities();
      this.cities = response.data;
    },

    async getDistrictsOfCity(city) {
      if (!this.cities.length) return;

      const provinceCode = this.cities.find(item => item.name === city).code;

      const response = await LocalAdministrationService.getDistrictsOfCity(
        provinceCode
      );

      this.districts = response.data;
    },

    async getWardsOfDistrict(district) {
      if (!this.districts.length) return;
      const districtCode = this.districts.find(item => item.name === district)
        .code;

      const response = await LocalAdministrationService.getWardsOfDistrict(
        districtCode
      );
      this.wards = response.data;
    }
  },

  watch: {
    editItemIndex: {
      async handler() {
        this.districts = [];
        this.wards = [];

        await this.getDistrictsOfCity(this.customerAddress.tinh_thanhpho);
        await this.getWardsOfDistrict(this.customerAddress.quan_huyen);
      }
    },
    "customerAddress.tinh_thanhpho": {
      async handler() {
        this.districts = [];

        await this.getDistrictsOfCity(this.customerAddress.tinh_thanhpho);
      }
    },
    "customerAddress.quan_huyen": {
      async handler() {
        this.wards = [];

        await this.getWardsOfDistrict(this.customerAddress.quan_huyen);
      }
    }
  }
};
</script>
