<template>
  <v-form ref="formRef">
    <v-card class="card px-5 pt-5 pb-3" flat>
      <div
        class="card-form"
        :style="itemIndex !== editItemIndex ? { paddingRight: '160px' } : null"
      >
        <!-- Start: Name -->
        <div class="form-item d-flex flex-row align-center">
          <div class="form-item--label font-weight-bold text-body-1 mr-2">
            Họ và tên:
          </div>
          <div class="form-item--input">
            <v-text-field
              v-if="isEditMode && itemIndex === editItemIndex"
              v-model="customerAddress.name"
              class="text-body-1"
              dense
              flat
              hide-details="auto"
              outlined
              placeholder="Nhập họ tên"
              required
              :rules="[v => !!v || 'Không được bỏ trống']"
            ></v-text-field>
            <div v-else class="">{{ item.name }}</div>
          </div>
        </div>
        <!-- End: Name -->
        <!-- Start: Phone -->
        <div class="form-item d-flex flex-row align-center mt-3">
          <div class="form-item--label font-weight-bold text-body-1 mr-2">
            SĐT:
          </div>
          <div class="form-item--input">
            <v-text-field
              v-if="isEditMode && itemIndex === editItemIndex"
              v-model="customerAddress.phone"
              v-mask="'##########'"
              class="text-body-1"
              dense
              flat
              hide-details="auto"
              outlined
              placeholder="Nhập số điện thoại"
              required
              :rules="[v => !!v || 'Không được bỏ trống']"
            ></v-text-field>
            <div v-else class="">
              <template v-if="item.phone">{{
                item.phone | VMask("### ### ####")
              }}</template>
            </div>
          </div>
        </div>
        <!-- End: Phone -->
        <!-- Start: Address -->
        <div class="form-item d-flex flex-row align-center mt-3">
          <div class="form-item--label font-weight-bold text-body-1 mr-2">
            Địa chỉ:
          </div>
          <div class="form-item--input">
            <v-row
              v-if="isEditMode && itemIndex === editItemIndex"
              class="my-0"
            >
              <!-- Start: City -->
              <v-col class="py-0" cols="4">
                <v-autocomplete
                  v-model="customerAddress.tinh_thanhpho"
                  class="text-body-1"
                  clearable
                  dense
                  flat
                  hide-details="auto"
                  :items="cities"
                  item-text="name"
                  item-value="name"
                  :menu-props="{ nudgeTop: '-40px' }"
                  no-data-text="Không có dữ liệu"
                  outlined
                  placeholder="Tỉnh/Thành phố"
                  :rules="[v => !!v || 'Không được bỏ trống']"
                  @change="customerAddress.quan_huyen = null"
                ></v-autocomplete>
              </v-col>
              <!-- End: City -->
              <!-- Start: District -->
              <v-col class="py-0" cols="4">
                <v-autocomplete
                  v-model="customerAddress.quan_huyen"
                  class="text-body-1"
                  clearable
                  dense
                  flat
                  hide-details="auto"
                  :items="districts"
                  item-text="name"
                  item-value="name"
                  :menu-props="{ nudgeTop: '-40px' }"
                  no-data-text="Không có dữ liệu"
                  outlined
                  placeholder="Quận/Huyện"
                  :rules="[v => !!v || 'Không được bỏ trống']"
                  @change="customerAddress.phuong_xa = null"
                ></v-autocomplete>
              </v-col>
              <!-- End: District -->
              <!-- Start: Ward -->
              <v-col class="py-0" cols="4">
                <v-autocomplete
                  v-model="customerAddress.phuong_xa"
                  class="text-body-1"
                  clearable
                  dense
                  flat
                  :items="wards"
                  item-text="name"
                  item-value="name"
                  :menu-props="{ nudgeTop: '-40px' }"
                  no-data-text="Không có dữ liệu"
                  outlined
                  placeholder="Phường/Xã"
                  solo
                  :rules="[v => !!v || 'Không được bỏ trống']"
                ></v-autocomplete>
              </v-col>
              <!-- End: Ward -->
              <!-- Start: Street -->
              <v-col class="py-0 mt-3" cols="12">
                <v-text-field
                  v-model="customerAddress.so_nha"
                  class="text-body-1"
                  dense
                  flat
                  hide-details
                  no-data-text="Không có dữ liệu"
                  outlined
                  placeholder="Tòa nhà, Tên đường,..."
                  solo
                ></v-text-field>
              </v-col>
              <!-- Start: Street -->
            </v-row>
            <div v-else>
              {{
                `${item.so_nha}, ${item.phuong_xa}, ${item.quan_huyen}, ${item.tinh_thanhpho}`
              }}
            </div>
          </div>
        </div>
        <!-- End: Address -->
      </div>
      <div
        class="card-action d-flex flex-row justify-end mt-3"
        :class="{ 'card-action--absolute mr-5': itemIndex !== editItemIndex }"
      >
        <template v-if="isEditMode && itemIndex === editItemIndex">
          <v-btn
            class="text-none grey lighten-4 rounded-lg mr-2"
            color="grey darken-1"
            text
            @click="turnOffEditMode()"
          >
            Hủy
          </v-btn>
          <v-btn
            v-if="customerAddress.id"
            class="text-none green lighten-5 rounded-lg"
            color="primary"
            text
            :loading="
              customerStatusRequest.value === 'loading-updateCustomerAddress'
            "
            @click="
              customerStatusRequest.value === 'loading-updateCustomerAddress'
                ? null
                : updateCustomerAddress()
            "
          >
            Lưu
          </v-btn>
          <v-btn
            v-else
            class="text-none green lighten-5 rounded-lg"
            color="primary"
            text
            :loading="
              customerStatusRequest.value === 'loading-createCustomerAddress'
            "
            @click="
              customerStatusRequest.value === 'loading-createCustomerAddress'
                ? null
                : createCustomerAddress()
            "
          >
            Thêm
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            class="rounded-lg font-weight-regular text-decoration-underline text-none"
            text
            @click="turnOnEditMode()"
          >
            Sửa
          </v-btn>
          <v-btn
            class="rounded-lg font-weight-regular text-decoration-underline text-none"
            text
            @click="deleteCustomerAddress()"
          >
            Xóa
          </v-btn>
        </template>
      </div>
    </v-card>
  </v-form>
</template>

<script>
// import LocalAdministrationService from "@/core/service/local-administration.service";

export default {
  props: {
    editItemIndex: {
      type: Number
    },
    isEditMode: {
      type: Boolean
    },
    item: {
      type: Object
    },
    itemIndex: {
      type: Number
    },
    cities: {
      type: Array
    },
    districts: {
      type: Array
    },
    wards: {
      type: Array
    }
  },

  data() {
    return {
      formRef: null
    };
  },

  computed: {
    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    },
    customerAddress() {
      return this.$store.getters["CUSTOMER/customerAddress"];
    },
    customerAddresses() {
      return this.$store.getters["CUSTOMER/customerAddresses"];
    },
    customerStatusRequest() {
      return this.$store.getters["CUSTOMER/statusRequest"];
    }
  },

  methods: {
    async createCustomerAddress() {
      const isValid = await this.$refs.formRef.validate();

      if (!isValid) return;
      // Set customer id
      this.customerAddress.of_user_id = this.customer.id;
      // Request create
      await this.$store.dispatch("CUSTOMER/createCustomerAddress", {
        index: this.itemIndex,
        data: this.customerAddress
      });
      // Alert
      if (
        this.customerStatusRequest.value === "success-createCustomerAddress"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm địa chỉ thành công"
          }
        });
      }
    },

    deleteCustomerAddress() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa địa chỉ khách hàng</span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch("CUSTOMER/deleteCustomerAddress", {
                index: this.itemIndex,
                data: this.item.id
              });
              if (
                this.customerStatusRequest.value ===
                "success-deleteCustomerAddress"
              ) {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa địa chỉ"
                  }
                });
              }
            }
          }
        }
      });
    },

    turnOffEditMode() {
      this.$emit("updateIsEditMode", false);
      this.$emit("updateEditItemIndex", null);
      if (!this.item.id) {
        this.customerAddresses.splice(this.itemIndex, 1);
      }
    },

    turnOnEditMode() {
      this.$store.dispatch("CUSTOMER/setCustomerAddress", this.item);
      this.$emit("updateIsEditMode", true);
      this.$emit("updateEditItemIndex", this.itemIndex);
    },

    async updateCustomerAddress() {
      // Request create
      await this.$store.dispatch("CUSTOMER/updateCustomerAddress", {
        index: this.itemIndex,
        data: this.customerAddress
      });
      // Alert
      if (
        this.customerStatusRequest.value === "success-updateCustomerAddress"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật địa chỉ thành công"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-item {
  &--label {
    width: 80px;
  }
  &--input {
    flex: 1;
  }
}

.card {
  position: relative;
  &-action {
    &--absolute {
      margin-top: 0 !important;
      position: absolute;
      right: 0;
      top: calc(50% - 18px);
    }
  }
}
</style>
