<template>
  <div class="text-body-1">
    <!-- Start: Alert -->
    <div
      v-if="customer.guest"
      class="amber lighten-5 text-body-1 rounded px-3 py-2 mb-5"
    >
      <span>
        Đây là tài khoản được tạo tự động khi khách ghé thăm website mà không
        đăng nhập. Với loại tài khoản này, bạn
        <span class="font-weight-bold">không thể chỉnh sửa thông tin</span>, hệ
        thống chỉ cho phép thêm tên định danh và chỉnh sửa địa chỉ.
      </span>
    </div>
    <!-- End: Alert -->
    <!-- Start: Alert -->
    <v-card v-if="!customer.id" class="mb-5" flat>
      <div class="amber lighten-5 text-body-1 px-3 py-2">
        <span>
          Hệ thống sẽ tự động tạo một tài khoản cho khách hàng trên Website
          <strong>thinkpro.vn</strong> với tên đăng nhập là
          <strong>Số điện thoại</strong>. Mật khẩu đăng nhập sẽ được gửi về số
          điện thoại của khách hàng trong ít phút ngay khi khách hàng được thêm
          mới.
        </span>
      </div>
    </v-card>
    <!-- End: Alert -->
    <!-- Start: Statistics -->
    <v-row v-else class="mb-5">
      <v-col
        v-for="(item, index) in customerStatistics"
        :key="index"
        class="py-0"
        cols="4"
      >
        <v-card :class="item.color" class="white" flat>
          <v-card-title class="font-weight-bold justify-center pa-3 pb-1">
            {{ item.text }}
          </v-card-title>
          <v-card-text
            :class="item.textColor"
            class="text-h6 font-weight-bold text-center px-3 pb-3"
          >
            <template v-if="customer[item.field]"
              >{{ customer[item.field] | formatCurrency }}
            </template>
            <template v-else>{{ 0 | formatCurrency }}</template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- End: Statistics -->
    <!-- Start: Form -->
    <v-card flat>
      <v-form ref="form" v-model="formValid" lazy-validation>
        <!-- Start: Required Info -->
        <div class="d-flex pt-5 pb-3 px-5">
          <!-- Start: Avatar -->
          <div class="pr-8">
            <div class="font-weight-bold mb-2">Ảnh đại diện</div>
            <tp-input-avatar
              :src="
                customer.avatar && customer.avatar.startsWith('media')
                  ? customer.avatar
                  : customer.avatar
                  ? customer.avatar
                  : undefined
              "
              height="120px"
              width="120px"
              @updateFormData="uploadAvatar"
            ></tp-input-avatar>
          </div>
          <!-- End: Avatar -->
          <v-row>
            <!-- Start: Customer type-->
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Loại khách hàng</div>
              <v-radio-group
                v-model="customer.customer_type"
                class="mt-0"
                dense
                hide-details
                row
              >
                <template v-for="item in customerTypesArray">
                  <v-radio :key="item.value" :value="item.value" size="small">
                    <template #label>{{ item.label }}</template>
                  </v-radio>
                </template>
              </v-radio-group>
            </v-col>
            <!-- End: Customer type-->
            <!-- Start: Tỉnh/Thành phố-->
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Tỉnh / Thành phố</div>
              <v-autocomplete
                v-model="customer.addresses[0].tinh_thanhpho"
                class="text-body-1"
                clearable
                dense
                flat
                :items="cities"
                item-text="name"
                item-value="name"
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Tỉnh/Thành phố"
                :rules="[v => !!v || 'Không được bỏ trống']"
                @change="handleChangeCity"
              ></v-autocomplete>
            </v-col>
            <!-- End: Tỉnh/Thành phố-->
            <!-- Start: Name -->
            <v-col v-if="!customer.guest" class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Tên khách hàng</div>
              <v-text-field
                v-model="customer.name"
                :disabled="customer.guest"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập tên khách hàng"
                required
              ></v-text-field>
            </v-col>
            <v-col v-else class="py-0" cols="6">
              <div class="font-weight-bold mb-2">
                Tên định danh
                <span class="font-weight-regular">({{ customer.name }})</span>
              </div>
              <v-text-field
                v-model="customer.alias"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập tên định danh"
                required
              ></v-text-field>
            </v-col>
            <!-- End: Name -->
            <!-- Start: Quận/Huyện-->
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Quận / Huyện</div>
              <v-autocomplete
                v-model="customer.addresses[0].quan_huyen"
                class="text-body-1"
                clearable
                dense
                flat
                :items="districts"
                item-text="name"
                item-value="name"
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Quận/Huyện"
                :rules="[v => !!v || 'Không được bỏ trống']"
                @change="handleChangeDistrict"
              ></v-autocomplete>
            </v-col>
            <!-- End: Quận/Huyện-->
            <!-- Start: Phone -->
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Số điện thoại</div>
              <tp-text-field
                v-model="customer.phone"
                :disabled="customer.guest"
                :validate="customer.guest ? undefined : 'tel'"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập số điện thoại"
              ></tp-text-field>
            </v-col>
            <!-- End: Phone -->
            <!-- Start: Phường/Xã-->
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Phường / Xã</div>
              <v-autocomplete
                v-model="customer.addresses[0].phuong_xa"
                class="text-body-1"
                clearable
                dense
                flat
                :items="wards"
                item-text="name"
                item-value="name"
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Phường/Xã"
                :rules="[v => !!v || 'Không được bỏ trống']"
              ></v-autocomplete>
            </v-col>
            <!-- End: Phường/Xã-->
            <!-- Start: Birthday -->
            <v-col
              v-if="customer.customer_type === customerTypes.CA_NHAN.value"
              class="py-0"
              cols="6"
            >
              <div class="mb-2 d-flex">
                <div
                  :class="{
                    'grey--text text--lighten-1': customer.birthType === 'YEAR'
                  }"
                  class="font-weight-bold cursor-pointer"
                  @click="customer.birthType = 'FULL'"
                >
                  Ngày sinh
                </div>

                <div class="mx-3 font-weight-bold">/</div>

                <div
                  :class="{
                    'grey--text text--lighten-1': customer.birthType === 'FULL'
                  }"
                  class="font-weight-bold cursor-pointer"
                  @click="customer.birthType = 'YEAR'"
                >
                  Năm sinh
                </div>
              </div>

              <tp-input-date
                v-if="customer.birthType === 'FULL'"
                :disabled="customer.guest"
                :max="new Date().toISOString().substr(0, 10)"
                :validate="'required'"
                :value="customer.dob"
                append-icon="mdi-calendar"
                custom-class="text-body-1"
                dense
                outlined
                placeholder="Chọn ngày"
                @change="customer.dob = $event"
              ></tp-input-date>

              <v-text-field
                v-if="customer.birthType === 'YEAR'"
                v-model="customer.yob"
                :rules="[rules.yearOfBirth]"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập năm sinh"
                type="number"
              ></v-text-field>
            </v-col>
            <!-- End: Birthday -->
            <!-- Start: Thuế -->
            <v-col v-else class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Mã số thuế</div>
              <v-text-field
                v-model="customer.taxcode"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập MST"
                required
                :rules="[v => !!v || 'Không được bỏ trống']"
              ></v-text-field>
            </v-col>
            <!-- End: Thuế-->
            <!-- Start: Địa chỉ -->
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Địa chỉ</div>
              <v-textarea
                v-model="customer.addresses[0].so_nha"
                class="text-body-1"
                dense
                outlined
                placeholder="Tòa nhà, tên đường,..."
                required
                rows="2"
              ></v-textarea>
            </v-col>
            <!-- End: Note -->
            <!-- Start: Gender -->
            <v-col
              v-if="customer.customer_type === customerTypes.CA_NHAN.value"
              class="py-0"
              cols="6"
            >
              <div class="font-weight-bold mb-2">Giới tính</div>
              <v-select
                v-model="customer.gender"
                :disabled="customer.guest"
                :items="genderList"
                :menu-props="{ nudgeTop: '-40px' }"
                :rules="[rules.selectedRequired]"
                class="text-body-1"
                dense
                item-text="text"
                item-value="value"
                outlined
                placeholder="Chọn giới tính"
              ></v-select>
            </v-col>
            <!-- End: Gender -->
            <!-- Start: Ngành nghề kinh doanh -->
            <v-col
              v-if="customer.customer_type === customerTypes.DOANH_NGHIEP.value"
              class="py-0"
              cols="6"
            >
              <div class="font-weight-bold mb-2">Nghành nghề kinh doanh</div>
              <v-combobox
                v-model="customer.job"
                :items="businessIndustries"
                item-value="name"
                item-text="name"
                class="tp-combobox text-body-1"
                dense
                outlined
                placeholder="Nhập hoặc chọn ngành nghề"
                single-line
              >
              </v-combobox>
            </v-col>
            <!-- End: Ngành nghề kinh doanh-->
            <!-- Start: Email -->
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Email</div>
              <v-text-field
                v-model="customer.email"
                :disabled="customer.guest"
                :rules="[rules.emailFormat]"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập email"
              ></v-text-field>
            </v-col>
            <!-- End: Email -->
            <!-- Start: Quy mô nhân sự -->
            <v-col
              v-if="customer.customer_type === customerTypes.DOANH_NGHIEP.value"
              class="py-0"
              cols="6"
            >
              <div class="font-weight-bold mb-2">Quy mô nhân sự</div>
              <v-combobox
                v-model="customer.employee_scale"
                :items="employeeScale"
                item-value="text"
                item-text="text"
                class="tp-combobox text-body-1"
                dense
                outlined
                placeholder="Nhập hoặc chọn ngành quy mô nhân sự"
                single-line
              >
              </v-combobox>
            </v-col>
            <!-- End: Quy mô nhân sự -->
            <!-- Start: Job -->
            <v-col
              v-if="customer.customer_type === customerTypes.CA_NHAN.value"
              class="py-0"
              cols="6"
            >
              <div class="font-weight-bold mb-2">Nghề nghiệp</div>
              <v-text-field
                v-model="customer.job"
                :disabled="customer.guest"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập nghề nghiệp"
              ></v-text-field>
            </v-col>
            <!-- End: Job -->
            <!-- Start: Facebook -->
            <v-col
              v-if="customer.customer_type === customerTypes.CA_NHAN.value"
              class="py-0"
              cols="6"
            >
              <div class="font-weight-bold mb-2">Facebook</div>
              <v-text-field
                v-model="customer.facebook"
                :disabled="customer.guest"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập đường dẫn"
              ></v-text-field>
            </v-col>
            <!-- End: Facebook -->

            <!-- Start: Identify Number -->
            <v-col
              v-if="customer.customer_type === customerTypes.CA_NHAN.value"
              class="py-0"
              cols="6"
            >
              <div class="font-weight-bold mb-2">CMND/CCCD</div>
              <v-text-field
                v-model="customer.cmnd"
                v-mask="'### ### ### ###'"
                :disabled="customer.guest"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập CMND"
              ></v-text-field>
            </v-col>
            <!-- End: Identify Number -->
            <!-- Start: Note -->
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Ghi chú</div>
              <v-textarea
                v-model="customer.note"
                :disabled="customer.guest"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập ghi chú"
                required
                rows="2"
              ></v-textarea>
            </v-col>
            <!-- End: Note -->
          </v-row>
        </div>
        <!-- End: Required Info -->
      </v-form>
    </v-card>
    <!-- End: Form -->
    <v-btn
      v-if="customer.id"
      :disabled="!formValid && !customer.guest"
      :loading="customerStatusRequest.value === 'loading-updateCustomer'"
      class="rounded-lg mt-5 mr-2"
      color="primary"
      depressed
      @click="
        customerStatusRequest.value === 'loading-updateCustomer'
          ? null
          : updateCustomer()
      "
    >
      Lưu
    </v-btn>
    <v-btn
      v-else
      :disabled="!formValid"
      :loading="customerStatusRequest.value === 'loading-createCustomer'"
      class="rounded-lg mt-5 mr-2"
      color="primary"
      depressed
      @click="
        customerStatusRequest.value === 'loading-createCustomer'
          ? null
          : createCustomer()
      "
    >
      Lưu
    </v-btn>
  </div>
</template>

<script>
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import {
  CUSTOMER_TYPES,
  BUSINESS_INDUSTRIES,
  EMPLOYEE_SCALE
} from "@/core/constant";
import LocalAdministrationService from "@/core/service/local-administration.service";

export default {
  data() {
    return {
      customerStatistics: [
        {
          field: "tong_mua",
          text: "Tổng mua",
          color: "green--text lighten-3",
          textColor: "green--text"
        },
        {
          field: "tong_tra",
          text: "Tổng trả",
          color: "deep-purple--text lighten-3",
          textColor: "deep-purple--text"
        },
        {
          field: "cong_no",
          text: "Công nợ",
          color: "orange--text lighten-4",
          textColor: "orange--text"
        }
      ],
      formDataAvatar: null,
      formValid: true,
      genderList: [
        { value: 0, text: "Nữ" },
        { value: 1, text: "Nam" }
      ],
      rules: {
        emailFormat: value => {
          if (this.regexEmail(value)) return true;
          else return "Email không đúng định dạng.";
        },
        phoneRequired: value => !!value || "Bạn chưa nhập số điện thoại.",
        phoneFormat: value => {
          if (this.regexPhone(value)) return true;
          else return "Số điện thoại không đúng.";
        },
        selectedRequired: value => value !== null || "Không được bỏ trống.",
        yearOfBirth: value => {
          const currentYear = new Date().getFullYear();
          const minYear = 1000;

          if (value >= minYear && value <= currentYear) return true;
          else return `Năm sinh không hợp lệ`;
        }
      },
      customerTypes: CUSTOMER_TYPES,
      businessIndustries: BUSINESS_INDUSTRIES,
      employeeScale: EMPLOYEE_SCALE,
      cities: [],
      districts: [],
      wards: []
    };
  },

  computed: {
    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    },
    customerStatusRequest() {
      return this.$store.getters["CUSTOMER/statusRequest"];
    },
    uploadedAvatar() {
      return this.$store.getters["CUSTOMER/uploadedAvatar"];
    },
    customerTypesArray() {
      return Object.values(this.customerTypes);
    }
  },

  methods: {
    async createCustomer() {
      await this.validate();

      if (this.formValid) {
        const routeQuery = this.$route.query;

        // Check avatar
        if (this.customer.avatar && this.customer.avatar.startsWith("blob:")) {
          await this.uploadAvatar();
        }

        if (this.customer.dob) {
          this.customer.yob = null;
        }

        if (this.customer.yob) {
          this.customer.dob = null;
        }

        // Request create
        await this.$store.dispatch("CUSTOMER/createCustomer", {
          data: { ...this.customer },
          routeQuery: {
            filters: {
              tradeStatus: routeQuery.trans_status
                ? routeQuery.trans_status
                : null,
              fromBuy: routeQuery.buy_from
                ? parseFloat(routeQuery.buy_from)
                : null,
              toBuy: routeQuery.buy_to ? parseFloat(routeQuery.buy_to) : null,
              fromReturn: routeQuery.return_from
                ? parseFloat(routeQuery.return_from)
                : null,
              toReturn: routeQuery.return_from
                ? parseFloat(routeQuery.return_from)
                : null,
              fromBalance: routeQuery.debt_from
                ? parseFloat(routeQuery.debt_from)
                : null,
              toBalance: routeQuery.debt_to
                ? parseFloat(routeQuery.debt_to)
                : null
            },
            search: routeQuery.search || null,
            per_page: parseInt(routeQuery.limit),
            cur_page: parseInt(routeQuery.page)
          }
        });
        // Alert
        if (this.customerStatusRequest.value === "success-createCustomer") {
          this.$emit("changeTabToAddAddress");
          this.$emit("onSuccessfulCreate");

          showToastActionAlert(this, {
            message: "Thêm mới khách hàng thành công"
          });
        } else if (
          this.customerStatusRequest.value === "error-createCustomer"
        ) {
          showModalAlertError(this, {
            title: "Lỗi thêm mới khách hàng",
            message: this.customerStatusRequest.message
          });
        }
      }
    },

    regexEmail(email) {
      const isEmail = new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
      );

      return isEmail.test(email);
    },

    regexPhone(phone) {
      const isVnMobilePhone = /^(0|\+84)(\s|\.)?((3[2-9])|(5[2689])|(7[06-9])|(8[1-9])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;

      return isVnMobilePhone.test(phone);
    },

    async updateCustomer() {
      await this.validate();

      if (this.formValid || this.customer.guest) {
        const routeQuery = this.$route.query;

        // Check avatar change
        if (this.customer.avatar && this.customer.avatar.startsWith("blob:")) {
          await this.uploadAvatar();
        }

        if (this.customer.birthType === "YEAR") {
          this.customer.dob = null;
        }

        if (this.customer.birthType === "FULL") {
          this.customer.yob = null;
        }

        // Request update
        await this.$store.dispatch("CUSTOMER/updateCustomer", {
          data: { ...this.customer },
          routeQuery: {
            filters: {
              tradeStatus: routeQuery.trans_status
                ? routeQuery.trans_status
                : null,
              fromBuy: routeQuery.buy_from
                ? parseFloat(routeQuery.buy_from)
                : null,
              toBuy: routeQuery.buy_to ? parseFloat(routeQuery.buy_to) : null,
              fromReturn: routeQuery.return_from
                ? parseFloat(routeQuery.return_from)
                : null,
              toReturn: routeQuery.return_from
                ? parseFloat(routeQuery.return_from)
                : null,
              fromBalance: routeQuery.debt_from
                ? parseFloat(routeQuery.debt_from)
                : null,
              toBalance: routeQuery.debt_to
                ? parseFloat(routeQuery.debt_to)
                : null
            },
            search: routeQuery.search || null,
            per_page: parseInt(routeQuery.limit),
            cur_page: parseInt(routeQuery.page)
          }
        });
        // Alert
        if (this.customerStatusRequest.value === "success-updateCustomer") {
          showToastActionAlert(this, {
            message: "Đã lưu cập nhật"
          });
        } else if (
          this.customerStatusRequest.value === "error-updateCustomer"
        ) {
          showModalAlertError(this, {
            title: "Lỗi cập nhật khách hàng",
            message: this.customerStatusRequest.message
          });
        }
      }
    },

    async uploadAvatar(value) {
      await this.$store.dispatch("CUSTOMER/uploadAvatar", value);
      // Set avatar
      if (this.customerStatusRequest.value === "success-uploadAvatar") {
        // Set uploaded avatar
        this.customer.avatar = this.uploadedAvatar;
      }
    },

    validate() {
      this.$refs.form.validate();
    },

    async getCities() {
      const response = await LocalAdministrationService.getCities();
      this.cities = response.data;
    },

    async getDistrictsOfCity(city) {
      if (!this.cities.length) return;

      const provinceCode = this.cities.find(item => item.name === city).code;

      const response = await LocalAdministrationService.getDistrictsOfCity(
        provinceCode
      );

      this.districts = response.data;
    },

    async getWardsOfDistrict(district) {
      if (!this.districts.length) return;
      const districtCode = this.districts.find(item => item.name === district)
        .code;

      const response = await LocalAdministrationService.getWardsOfDistrict(
        districtCode
      );
      this.wards = response.data;
    },

    async handleChangeCity(city) {
      this.customer.addresses[0].quan_huyen = null;
      await this.getDistrictsOfCity(city);
    },

    async handleChangeDistrict(district) {
      this.customer.addresses[0].phuong_xa = null;
      await this.getWardsOfDistrict(district);
    }
  },

  async created() {
    await this.getCities();

    if (this.customer.addresses[0].tinh_thanhpho) {
      await this.getDistrictsOfCity(this.customer.addresses[0].tinh_thanhpho);
    }

    if (this.customer.addresses[0].quan_huyen) {
      await this.getWardsOfDistrict(this.customer.addresses[0].quan_huyen);
    }
  },

  watch: {
    "customer.id": {
      async handler(val) {
        if (val) {
          this.districts = [];
          this.wards = [];

          await this.getDistrictsOfCity(
            this.customer.addresses[0].tinh_thanhpho
          );
          await this.getWardsOfDistrict(this.customer.addresses[0].quan_huyen);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.item-info {
  font-size: 1rem;

  &--value {
    font-size: 1.125rem;
    font-weight: 500;
  }
}

.v-input--selection-controls {
  padding-top: 0 !important;
}

.v-radio {
  width: calc(100% / 2 - 4px);
  height: 40px;
  padding: 0 8px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  margin: 0 !important;
  border-radius: 4px;
}

.v-radio:first-child {
  margin-right: 8px !important;
}
</style>
